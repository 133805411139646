export const dataDeletionInstructionSentences = [
    {
      phase: 0,
      description: "Data Deletion Instructions"
    },
    {
      phase: 1,
      description: "Before requesting deletion of your user account data, please ensure that you understand that:"
    },
    {
      phase: 2,
      description: "If you have an outstanding loan that has not been settled, we do not allow you to delete your accoun"
    },
    {
      phase: 3,
      description: "Account deletion is final. There will be no way to restore your account."
    },
    {
      phase: 4,
      description: "If you are sure that you wish to delete your user account data, please perform account deletion in the B app by navigating to “Settings” page, find “Data Deletion” and complete the required steps."
    },
  ];
  