import { Layout } from "antd";
import React from "react";
import RouteList from "../../route/RouteList";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

function Home() {
  const { Content } = Layout;
  return (
    <div>
      <Layout
        style={{
          overflow: "hidden",
          backgroundColor: "#eef8fb",
          minHeight: "100vh",
        }}
      >
        <Navbar />

        <Content style={{ overflow: "hidden", backgroundColor: "#fff4f9" }}>
          <RouteList />
        </Content>

        <Layout style={{ overflow: "hidden", backgroundColor: "#fff4f9" }}>
          <Footer />
        </Layout>
      </Layout>
    </div>
  );
}

export default Home;
