import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  gb: {
    translation: {
      zeroInterest: "0% Interest , Zero Upfront Fee",
      upfrontPaymentAndInterestFree:
        "Upfront Payment and Interest-Free , Definitely Your Go-to-Loan Platform!",
      iWantToApplyForA: "I Want To Apply For A",
      Loan: "loan",
      interestRate: "Interest Rate",
      repaymentAmount: "Repayment Amount",
      tenurePeriod: "Tenure Period",
      days: "Days",
      applyNow: "Apply Now",
      homeDesc1: "all it takes aret three effortless steps",
      signUp: "Sign Up",
      step1Desc:
        "Complete a simple sign up process through our app or website.",
      step2Desc:
        "Complete a simple sign up process through our app or website.",
      whyUs: "Why Us ?",
      noUpfrontPayment: "No Upfront Payment",
      whyUsDesc1:
        "Unitycash aspires to reduce your burden, thus upfront payment is not required to requrest for a loan at our plarform.",
      simpleandEasytoInstall: "Simple and Easy to Install",
      whyUsDesc2:
        "An easy yet secure system is the way to go. Apply a loan via Beauty Loan's simple procedures and receive your cash 3 minutes after approval.",
      zeroInterest1: "Zero Interest",
      whyUsDesc3:
        "   Interest is not cool. Our micro-finance solution provides you timely support without having you worry about repayments.",
      oneStopCentre: "One Stop Centre",
      whyUsDesc4:
        "Time is money. We save you the hassie to go from bank to bank just to get a loan done.",
      downloadBeautyLoanToday: "Download Beauty Loan Today",
      fifthDesc:
        "Enjoy seamless and fast loan today. No interest or upfront fee.Now available on IOS and Android",
      scanQr: "Scan Qr Code",
      downloadOn: "Download On",
      howCanWeHelpU: "How Can We Help You",
      gotAQuestion: "Got A Question? We are here to answer",
      faq: "Frequently Asked Question",
      popular: "Popular",
      loanApplication: "Loan Application",
      Repayment: "Repayment",
      Others: "Others",
      FAQDesc1:
        "Beauty Loan provides individual with a fast microfinance loan platform. It now offers loans up to RM15,000 in monthly instalment for emergency assistance. When your application is approved, the loan will be directly deposited into the bank account/e-wallet that you have registered.",
      FAQDesc2:
        "Kindly visit our website at Beauty Loan.com or download Beauty Loan app from Google Play Store or App Store for fast loan.",
      FAQDesc3:
        "Beauty Loan offers loan services to MALAYSIAN aged 18 years old to 60 years old.",
      FAQDesc4: "You do not need a guarantor to apply a loan at Beauty Loan.",
      FAQDesc5:
        "Please check your ID and selfie video before submitting your application again.",
      FAQDesc6:
        "A borrower can pay loans via three channels which is Online Banking , Western Union , Cash Deposit",
      FAQDesc7:
        "Should the borrower cannot repay on time, he is required to pay compound penalty.",
      FAQDesc8:
        "Please enter your mobile number and wait for the SMS verification code sent by Beauty Loan. Enter the code to complete verification.",
      FAQDesc9:
        "At this moment, our system does not support any changes to mobile number. Kindly contact our Customer Service if there is any changes.",
      FAQDesc10:
        "Beauty Loan will send you an automated voice message to remind you for upcoming repayments. However, it is your responsibility to repay your loan on time.",
      Download: "Download",
      versionGetStarted: "Please select a version below to get staretd",
      letsConnect: "Let's Connect",
      contactUs: "Contact us",
      letsContactDesc:
        "We are more that glad to help you.Also ,check out our FAQ for general enquiries.",
      sendUsMessage: "Send us Your Message",
      sendUsMessageDesc:
        "Feel free to drop a message and we will get back to you as soon as possible",
      AboutUs: "About Us",
      AboutUsTitle:
        "Upfront Payment and Interest Free, Definitely Your Go-to Loan Platform!",
      AboutUsDesc:
        "What makes Beauty Loan stand our in a crowd of loan platforms is its expert system that requires no excessive information or documents from clients to process a loan.Our programme is tailored to handle unexpected personal financial needs, which is why we only included a brief review of clients' basic information in the loan procedures. To top ut all, Beauty Loan eliminates all forms of prepayments and Interests for its clients.",
      theFastestLoanExperience: "The Fastest Loan Experience",
      fastestDesc:
        "Gone are the days when loan takes several days to approve. It takes only 15 minutes now!",
      aboutUsDesc1: "Complete a Simple Application Form via Our App",
      aboutUsDesc2:
        "Submit a loan request and we will complete your information review within 3 minutes",
      aboutUsDesc3:
        "Once we have approved your information, the loan will be distributed within 3 minutes",
      DownloadNow: "Download Now",
      Submission: "Submission",
      Review: "Review",
      Disbursement: "Disbursement",
      operatingHour: "Operating Hour",
      ContactNumber: "Contact Number",
      Address: "Address",
      QuickLink: "Quick Link",
      CashPickupOptions: "Cash Pick up Options",
      Atselectedbranchesof: "At selected branches of",
      SocialMedia: "Social Media",
      Home: "Home",
      About: "About",
      FAQs: "FAQs",
      Contact: "Contact",
      FAQTitle1: "What are Beauty Loan's loan limit and loan term?",
      FAQTitle2: "How to apply for an Beauty Loan loan?",
      FAQTitle3: "Who is eligible for Beauty Loan's loan services?",
      FAQTitle4: "Do I need a guarantor?",
      FAQTitle5: "If my application is rejected, can I apply again?",
      FAQTitle6: "How to make payment?",
      FAQTitle7: "What happens if a borrower fails to pay on time?",
      FAQTitle8: "How to make a verification on a mobile phone?",
      FAQTitle9: "How to change mobile number?",
      FAQTitle10: "Are you going to send me a repayment reminder?",
    },
  },
  cn: {
    translation: {
      zeroInterest: "0% 利息，零预付费用",
      upfrontPaymentAndInterestFree: "预付款免息，绝对是您的首选贷款平台！",
      iWantToApplyForA: "我想申请",
      Loan: "贷款",
      interestRate: "利率",
      repaymentAmount: "还款金额",
      tenurePeriod: "任期",
      days: "天",
      applyNow: "现在申请",
      homeDesc1: "只需三个轻松的步骤",
      signUp: "报名",
      step1Desc: "通过我们的应用程序或网站完成简单的注册过程。",
      step2Desc: "通过我们的应用程序或网站完成简单的注册过程。",
      whyUs: "为什么是我们?",
      noUpfrontPayment: "No Upfront Payment",
      whyUsDesc1:
        "Unitycash 致力于减轻您的负担，因此在我们的平台申请贷款不需要预付款.",
      simpleandEasytoInstall: "简单且易于安装",
      whyUsDesc2:
        "一个简单而安全的系统是正确的选择。 通过Beauty Loan简单的程序申请贷款,批准后3分钟即可收到现金。",
      zeroInterest1: "零利息",
      whyUsDesc3:
        "兴趣并不酷。 我们的小额信贷解决方案为您提供及时的支持，让您无需担心还款问题。",
      oneStopCentre: "一站式中心",
      whyUsDesc4:
        "时间就是金钱. 我们为您节省了从一家银行到另一家银行以获得贷款的麻烦.",
      downloadBeautyLoanToday: "立即下载 Beauty Loan",
      fifthDesc:
        "E立即享受无缝且快速的贷款。 无利息或预付费用。现已在 IOS 和 Android 上提供",
      scanQr: "扫描二维码",
      downloadOn: "下载于",
      howCanWeHelpU: "我们如何帮助您",
      gotAQuestion: "有问题吗？ 我们在这里为您解答",
      faq: "常见问题",
      popular: "常见的",
      loanApplication: "申请贷款",
      Repayment: "还款",
      Others: "其他",
      FAQDesc1:
        "蚂蚁贷为个人提供了一个快速的小额信贷平台。它现在提供高达 RM15,000 的每月分期贷款以供紧急援助。当您的申请获得批准后，贷款将直接存入您拥有的银行账户/电子钱包 挂号的.",
      FAQDesc2:
        "请访问我们的网站Beauty Loan.com或从Google Play Store或App Store下载Beauty Loan应用程序以进行快速贷款。",
      FAQDesc3: "Beauty Loan 为 18 岁至 60 岁的马来西亚人提供贷款服务.",
      FAQDesc4: "您无需担保人即可在 Beauty Loan 申请贷款.",
      FAQDesc5: "请在再次提交申请前检查您的身份证件和自拍照视频。",
      FAQDesc6: "借款人可以通过网上银行、西联汇款、现金存款三种渠道支付贷款",
      FAQDesc7: "如果借款人不能按时还款，则需要支付复合罚款.",
      FAQDesc8:
        "请输入您的手机号码，等待蚂蚁贷发送短信验证码。 输入验证码完成验证.",
      FAQDesc9: "目前，我们的系统不支持更改手机号码。 如有变动请联系客服",
      FAQDesc10:
        "Beauty Loan 将向您发送自动语音消息，提醒您即将还款。 但是，您有责任按时偿还贷款.",
      Download: "下载",
      versionGetStarted: "请选择下面的版本以开始使用",
      letsConnect: "让我们联系吧",
      contactUs: "联系我们",
      letsContactDesc:
        "我们非常乐意为您提供帮助。另外，请查看我们的常见问题解答以了解一般疑问.",
      sendUsMessage: "给我们留言",
      sendUsMessageDesc: "请随时留言，我们会尽快回复您",
      AboutUs: "关于我们",
      AboutUsTitle: "首付免息，绝对是您的首选贷款平台",
      AboutUsDesc:
        "Beauty Loan 在众多贷款平台中脱颖而出的原因在于它的专家系统，不需要客户提供过多的信息或文件即可处理贷款。我们的计划是为处理意外的个人财务需求而量身定制的，这就是为什么我们只对 贷款手续中客户的基本信息。 最重要的是,Beauty Loan 为客户消除了所有形式的预付款和利息.",
      theFastestLoanExperience: "最快的贷款体验",
      fastestDesc:
        "贷款需要几天时间才能批准的日子已经一去不复返了。 现在只需要15分钟!",
      aboutUsDesc1: "通过我们的应用程序填写简单的申请表",
      aboutUsDesc2: "提交贷款申请,我们将在3分钟内完成您的信息审核",
      aboutUsDesc3: "一旦我们批准您的信息,贷款将在3分钟内发放",
      DownloadNow: "现在下载",
      Submission: "提交",
      Review: "审查",
      Disbursement: "支出",
      operatingHour: "营业时间",
      ContactNumber: "联系号码",
      Address: "地址",
      QuickLink: "快速链接",
      CashPickupOptions: "现金领取选项",
      Atselectedbranchesof: "于指定分行",
      SocialMedia: "社交媒体",
      Home: "首页",
      About: "关于",
      FAQs: "FAQs",
      Contact: "联系",
      FAQTitle1: "Beauty Loan的贷款限额和贷款期限是多少?",
      FAQTitle2: "如何申请蚂蚁金服贷款?",
      FAQTitle3: "谁有资格享受 Beauty Loan 的贷款服务?",
      FAQTitle4: "我需要担保人吗?",
      FAQTitle5: "如果我的申请被拒绝，我可以再次申请吗?",
      FAQTitle6: "如何付款?",
      FAQTitle7: "如果借款人未能按时还款会怎样?",
      FAQTitle8: "如何在手机上进行验证?",
      FAQTitle9: "如何更改手机号码?",
      FAQTitle10: "您要向我发送还款提醒吗?",
    },
  },
  my: {
    translation: {
      zeroInterest: "0% Faedah , Sifar Yuran Pendahuluan",
      upfrontPaymentAndInterestFree:
        "Bayaran Pendahuluan dan Tanpa Faedah , Pastinya Platform Pinjaman Anda!",
      iWantToApplyForA: "Saya Ingin Memohon",
      Loan: "pinjaman",
      interestRate: "Kadar bunga",
      repaymentAmount: "Jumlah Bayaran Balik",
      tenurePeriod: "Tempoh Pegangan",
      days: "Hari",
      applyNow: "Mohon sekarang",
      homeDesc1: "apa yang diperlukan hanyalah tiga langkah mudah",
      signUp: "Daftar",
      step1Desc:
        "Lengkapkan proses pendaftaran mudah melalui apl atau tapak web kami.",
      step2Desc:
        "Lengkapkan proses pendaftaran mudah melalui apl atau tapak web kami.",
      whyUs: "Kenapa kita ?",
      noUpfrontPayment: "Tiada Bayaran Pendahuluan",
      whyUsDesc1:
        "Unitycash berhasrat untuk mengurangkan beban anda, oleh itu bayaran pendahuluan tidak diperlukan untuk meminta pinjaman di platform kami.",
      simpleandEasytoInstall: "Mudah dan Mudah Dipasang",
      whyUsDesc2:
        "Sistem yang mudah tetapi selamat adalah cara untuk pergi. Mohon pinjaman melalui prosedur mudah Beauty Loan dan terima wang tunai anda 3 minit selepas diluluskan.",
      zeroInterest1: "Kosong Faedah",
      whyUsDesc3:
        "Minat tidak hebat. Penyelesaian kewangan mikro kami memberikan anda sokongan tepat pada masanya tanpa perlu anda risau tentang pembayaran balik.",
      oneStopCentre: "Pusat Setempat",
      whyUsDesc4:
        "Masa adalah wang. Kami menjimatkan keperluan anda untuk pergi dari bank ke bank hanya untuk menyelesaikan pinjaman.",
      downloadBeautyLoanToday: "Muat turun Beauty Loan Hari Ini",
      fifthDesc:
        "Nikmati pinjaman lancar dan cepat hari ini. Tiada faedah atau yuran pendahuluan. Kini tersedia pada IOS dan Android",
      scanQr: "Imbas Kod Qr",
      downloadOn: "Muat Turun Di",
      howCanWeHelpU: "Bagaimana Kami Boleh Membantu Anda",
      gotAQuestion: "Ada Soalan? Kami di sini untuk menjawab",
      faq: "Soalan Lazim",
      popular: "Popular",
      loanApplication: "Permohonan pinjaman",
      Repayment: "Bayaran balik",
      Others: "Lain-lain",
      FAQDesc1:
        "Beauty Loan menyediakan individu dengan platform pinjaman kewangan mikro yang pantas. Ia kini menawarkan pinjaman sehingga RM15,000 secara ansuran bulanan untuk bantuan kecemasan. Apabila permohonan anda diluluskan, pinjaman akan dimasukkan terus ke dalam akaun bank/e-dompet yang anda miliki berdaftar.",
      FAQDesc2:
        "Sila layari laman web kami di Beauty Loan.com atau muat turun aplikasi Beauty Loan daripada Google Play Store atau App Store untuk pinjaman pantas.",
      FAQDesc3:
        "Beauty Loan menawarkan perkhidmatan pinjaman kepada warga MALAYSIA yang berumur 18 tahun hingga 60 tahun.",
      FAQDesc4:
        "Anda tidak memerlukan penjamin untuk memohon pinjaman di Beauty Loan.",
      FAQDesc5:
        "Sila semak ID dan video selfie anda sebelum menyerahkan permohonan anda sekali lagi.",
      FAQDesc6:
        "Peminjam boleh membayar pinjaman melalui tiga saluran iaitu Perbankan Dalam Talian , Western Union , Deposit Tunai",
      FAQDesc7:
        "Sekiranya peminjam tidak dapat membayar balik tepat pada masanya, dia dikehendaki membayar penalti kompaun.",
      FAQDesc8:
        "Sila masukkan nombor telefon bimbit anda dan tunggu kod pengesahan SMS dihantar oleh Beauty Loan. Masukkan kod untuk melengkapkan pengesahan.",
      FAQDesc9:
        "Pada masa ini, sistem kami tidak menyokong sebarang perubahan pada nombor mudah alih. Sila hubungi Khidmat Pelanggan kami jika terdapat sebarang perubahan.",
      FAQDesc10:
        "Beauty Loan akan menghantar mesej suara automatik kepada anda untuk mengingatkan anda untuk pembayaran balik yang akan datang. Walau bagaimanapun, adalah menjadi tanggungjawab anda untuk membayar balik pinjaman anda tepat pada masanya.",
      Download: "Muat Turun",
      versionGetStarted: "Sila pilih versi di bawah untuk bermula",
      letsConnect: "Mari Berhubung",
      contactUs: "Hubungi Kami",
      letsContactDesc:
        "Kami lebih gembira untuk membantu anda. Juga, semak Soalan Lazim kami untuk pertanyaan umum.",
      sendUsMessage: "Hantar Mesej Anda kepada kami",
      sendUsMessageDesc:
        "Jangan ragu untuk menghantar mesej dan kami akan menghubungi anda secepat mungkin",
      AboutUs: "Tentang kita",
      AboutUsTitle:
        "Bayaran Pendahuluan dan Tanpa Faedah, Pastinya Platform Pinjaman Pilihan Anda",
      AboutUsDesc:
        "Apa yang membuatkan Beauty Loan menjadi milik kami dalam banyak platform pinjaman ialah sistem pakarnya yang tidak memerlukan maklumat atau dokumen berlebihan daripada pelanggan untuk memproses pinjaman. Program kami disesuaikan untuk mengendalikan keperluan kewangan peribadi yang tidak dijangka, itulah sebabnya kami hanya menyertakan semakan ringkas tentang maklumat asas pelanggan dalam prosedur pinjaman. Di samping itu, Beauty Loan menghapuskan semua bentuk prapembayaran dan Faedah untuk pelanggannya",
      theFastestLoanExperience: "Pengalaman Pinjaman Terpantas",
      fastestDesc:
        "Sudah berlalu apabila pinjaman mengambil masa beberapa hari untuk diluluskan. Ia hanya mengambil masa 15 minit sekarang!",
      aboutUsDesc1: "Lengkapkan Borang Permohonan Mudah melalui Apl Kami",
      aboutUsDesc2:
        "Kemukakan permintaan pinjaman dan kami akan melengkapkan semakan maklumat anda dalam masa 3 minit",
      aboutUsDesc3:
        "Setelah kami meluluskan maklumat anda, pinjaman akan diagihkan dalam masa 3 minit",
      DownloadNow: "Muat turun sekarang",
      Submission: "Penyerahan",
      Review: "Semakan",
      Disbursement: "Pengeluaran",
      operatingHour: "Waktu Operasi",
      ContactNumber: "Nombor telefon",
      Address: "Alamat",
      QuickLink: "Pautan Pantas",
      CashPickupOptions: "Pilihan Ambil Tunai",
      Atselectedbranchesof: "Di cawangan terpilih",
      SocialMedia: "Media Sosial",
      Home: "Home",
      About: "Tentang",
      FAQs: "FAQs",
      Contact: "Kenalan",
      FAQTitle1: "Apakah had pinjaman Beauty Loan dan tempoh pinjaman?",
      FAQTitle2: "Bagaimana untuk memohon pinjaman Beauty Loan?",
      FAQTitle3: "Siapa yang layak mendapat perkhidmatan pinjaman Beauty Loan?",
      FAQTitle4: "Adakah saya memerlukan penjamin?",
      FAQTitle5: "Jika permohonan saya ditolak, bolehkah saya memohon semula?",
      FAQTitle6: "Cara membuat pembayaran?",
      FAQTitle7:
        "Apa yang berlaku jika peminjam gagal membayar tepat pada masanya?",
      FAQTitle8: "Cara membuat pengesahan pada telefon bimbit?",
      FAQTitle9: "Bagaimana untuk menukar nombor telefon bimbit?",
      FAQTitle10:
        "Adakah anda akan menghantar saya peringatan pembayaran balik?",
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "gb",
});

export default i18n;
