import React, { useState } from "react";
import "./navbar.scss";
import { Button, Col, Drawer, Menu, Row, Select } from "antd";
import Logo from "../../images/logo.png";
import { ArrNavbar } from "./ArrNavbar";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Navbar() {
  const [isLang, setIsLang] = useState("ENGLISH");
  const { i18n, t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const DrawerMenuItem = [
    {
      label: "Home",
      key: "home",
      onClick: () => {
        history.push("/home");
      },
    },
    {
      label: "About",
      key: "About",
      onClick: () => {
        history.push("/About");
      },
    },
    {
      label: "FAQs",
      key: "FAQs",
      onClick: () => {
        history.push("/FAQs");
      },
    },
    {
      label: "Contact",
      key: "Contact",
      onClick: () => {
        history.push("/Contact");
      },
    },
    {
      label: "Download",
      key: "Download",
      onClick: () => {
        history.push("/Download");
      },
    },
  ];

  function handleChangeLanguage(lang) {
    console.log(lang);
    i18n.changeLanguage(lang);
  }

  return (
    <div className="navbar-container">
      <Row justify="space-between">
        <Col xl={10} lg={8} sm={6} xs={6}>
          <div className="navbar-logo">
            <img
              width="300"
              src={Logo}
              alt="logo"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            />
          </div>
        </Col>
        <Col xl={10} lg={16} sm={18} xs={0}>
          <Row justify="space-around" className="navbar-menu-container">
            {ArrNavbar?.map((items) => {
              return (
                <Col sm={4} key={items.text}>
                  <div
                    className={`navbar-menu navbar-menu-${items.text}`}
                    onClick={() => history.push(`/${items.text}`)}
                  >
                    {t(items.text)}
                  </div>
                </Col>
              );
            })}
            <Col xs={4} className="language">
              <Select defaultValue={isLang} onChange={handleChangeLanguage}>
                <Select.Option value="gb">ENGLISH</Select.Option>
                <Select.Option value="my">MALAY</Select.Option>
                <Select.Option value="cn">CHINESE</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xxl={0} xl={0} lg={0} sm={0} xs={12}>
          <div className="navbar-menu-small">
            <Button
              icon={<MenuUnfoldOutlined />}
              style={{
                backgroundColor: "#ddf1f7",
                border: "1px solid #d679a6",
              }}
              onClick={() => setOpenMenu(!openMenu)}
            ></Button>
          </div>

          <Drawer
            width={300}
            closable={false}
            onClose={() => setOpenMenu(false)}
            open={openMenu}
            bodyStyle={{ backgroundColor: "#d679a6", padding: "0" }}
          >
            <Menu theme="dark" mode="inline" items={DrawerMenuItem} />
          </Drawer>
        </Col>
      </Row>
    </div>
  );
}

export default Navbar;
